import Rutas from "./routes/Routes";



function App() {
    return (

        <div className="app-wrapper">
            <Rutas />
        </div>

    );
}

export default App;
