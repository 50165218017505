import Cover from "../components/cover/Cover";


const Home = () => {

  return (

    <div>
      <Cover />
    </div>

  );
};

export default Home;